import React, { Suspense, useState, ReactElement } from 'react';
import { LogoutOutlined } from '@ant-design/icons';
import { Avatar, Layout, Menu, Dropdown, Tooltip } from 'antd';
import { Link } from 'react-router-dom';

import LoaderOverlay from 'shared/components/LoaderOverlay';
import { AuthConsumer } from 'shared/contexts/AuthContext';

import './Layout.scss';
import logoIcon from 'shared/images/logo_white.svg';
import logoText from 'shared/images/text_white.svg';
import {gql} from "apollo-boost";
import { useQuery } from '@apollo/react-hooks';

const GET_VERSION = gql`
    query GetVersion {
        appVersion
    }
`;

const GET_RECETTE = gql`
    query GetRecette {
        appRecette
    }
`;

export interface MenuItem {
    route: string;
    icon: ReactElement;
    label: string;
    warning?: ReactElement;
    daysBeforeQBExpiration?: number;
    disabled?: boolean;
}

interface LayoutProps {
    menuItems?: MenuItem[];
    selectedMenu?: string | null;
    onMenuClick?: (route: string) => void;
    children: any;
    hasHeader?: boolean;
    logout?: boolean;
}

export default function DesktopLayout({
    menuItems,
    selectedMenu,
    onMenuClick,
    children,
    hasHeader,
    logout,
}: LayoutProps) {
    const [menuCollapsed, setMenuCollapsed] = useState(
        localStorage.getItem('sidebar-collapsed') === 'true',
    );

    const { data } = useQuery(GET_VERSION);
    const version = data?.appVersion || 'vX.X.X';

    const { data: dataRecette, loading, error } = useQuery(GET_RECETTE);
    const recette = dataRecette?.appRecette;
    function onCollapse(collapsed: boolean) {
        setMenuCollapsed(collapsed);
        localStorage.setItem('sidebar-collapsed', collapsed.toString());
    }

    const content = (
        <Layout.Content>
            <Suspense fallback={<LoaderOverlay size="large" loading={true} />}>
                {children}
            </Suspense>
        </Layout.Content>
    );
    return (
        <Layout
            style={{minHeight: '100vh'}}
            className={`desktop-layout ${menuCollapsed ? '--collapsed' : ''}`}
        >
            <Layout.Sider
                collapsible
                collapsed={menuCollapsed}
                onCollapse={onCollapse}
                className="content-scrollable"
            >
                <Link to="/">
                    <div className="logo">
                        <img className="__icon" alt="Accueil" src={logoIcon}/>
                        <img className="__text" alt="Accueil" src={logoText}/>
                    </div>
                    <div className="kapela">Géré par kapela - {version}</div>
                </Link>

                <AuthConsumer>
                    {({updateAuthToken}) => (
                        <Menu
                            theme="dark"
                            onClick={({key}) => {
                                if (key === 'logout') {
                                    updateAuthToken(null);
                                } else {
                                    onMenuClick && onMenuClick(key);
                                }
                            }}
                            selectedKeys={selectedMenu ? [selectedMenu] : []}
                            mode="inline"
                        >
                            {menuItems && menuItems.map((item) => (
                                <Menu.Item key={item.route} disabled={item.disabled}>
                                    {item.icon}
                                    <span>{item.label}</span>
                                    {'\t'}
                                    {item.warning && (
                                        <Tooltip
                                            title={`La connection à Quickbooks expire dans ${
                                                item.daysBeforeQBExpiration
                                            } jour${
                                                item.daysBeforeQBExpiration &&
                                                item.daysBeforeQBExpiration >
                                                1 &&
                                                's'
                                            }`}
                                        >
                                            {item.warning}
                                        </Tooltip>
                                    )}
                                </Menu.Item>
                            ))}
                            {logout && (
                                <Menu.Item key="logout">
                                    <LogoutOutlined/> <span>Déconnexion</span>
                                </Menu.Item>
                            )}

                        </Menu>
                    )}

                </AuthConsumer>

            </Layout.Sider>
            {hasHeader ? (
                <Layout>
                    <Layout.Header>
                        <UserAvatar/>
                    </Layout.Header>
                    {content}
                </Layout>
            ) : (
                content

            )}
            {recette && (
                <div className="recette-banner">
                    Vous êtes actuellement sur une version de Recette, aucune donnée n'est sauvegardée dans la base de
                    données actuelle du Back Office en production.
                </div>
            )}
        </Layout>
    );
}

function UserAvatar() {
    return (
        <AuthConsumer>
            {({ user, updateAuthToken }) =>
                user && (
                    <Dropdown
                        overlay={
                            <Menu
                                onClick={({ key }) => {
                                    if (key === 'logout') {
                                        updateAuthToken(null);
                                    }
                                }}
                            >
                                <Menu.Item key="logout">
                                    <LogoutOutlined /> Déconnexion
                                </Menu.Item>
                            </Menu>
                        }
                        trigger={['click']}
                        placement="bottomRight"
                    >
                        <Avatar size="large">
                            {`${user.firstName[0]}${user.lastName[0]}`.toUpperCase()}
                        </Avatar>
                    </Dropdown>
                )
            }
        </AuthConsumer>
    );
}
