import React, { useState } from "react";
import { Tooltip, Button } from "antd";
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import moment from 'moment';
import 'moment/locale/fr'; // Ajout pour afficher les mois en français

moment.locale('fr'); // Configuration de moment en français

interface Props {
    value: string;
    onChange: (v: string) => void;
}

export default function MonthSelect({ value, onChange }: Props) {
    const initialMonth = moment().format('MM-YYYY'); // Format interne pour la logique
    const [currentMonth, setCurrentMonth] = useState(value || initialMonth);

    const formatDisplayMonth = (month: string) => {
        // Convertit MM-YYYY en "NOM DU MOIS ANNÉE" (tout en majuscules)
        return moment(month, 'MM-YYYY').format('MMMM YYYY').toUpperCase();
    };


    const previousMonth = () => {
        const currentMonthMoment = moment(currentMonth, 'MM-YYYY', true);

        if (currentMonthMoment.isValid()) {
            const newMonth = currentMonthMoment.subtract(1, 'months').format('MM-YYYY');
            setCurrentMonth(newMonth);
            onChange(newMonth);
        } else {
            console.error('Invalid date format');
        }
    };

    const nextMonth = () => {
        const currentMonthMoment = moment(currentMonth, 'MM-YYYY', true);

        if (currentMonthMoment.isValid()) {
            const newMonth = currentMonthMoment.add(1, 'months').format('MM-YYYY');
            setCurrentMonth(newMonth);
            onChange(newMonth);
        } else {
            console.error('Invalid date format');
        }
    };

    return (
        <div className="month-select mb-4">
            <div style={{ display: 'inline-block', marginBottom: 32 }}>
                <b>
                    {formatDisplayMonth(currentMonth)}
                </b>
                <Tooltip title="Mois précédent">
                    <Button
                        shape="circle"
                        icon={<LeftOutlined />}
                        onClick={previousMonth}
                        style={{ float: 'left', marginRight: '60px' }}
                    />
                </Tooltip>
                <Tooltip title="Mois suivant">
                    <Button
                        shape="circle"
                        icon={<RightOutlined />}
                        onClick={nextMonth}
                        style={{ float: 'right', marginLeft: '60px' }}
                    />
                </Tooltip>
            </div>
        </div>
    );
}
