import React from "react";
import { Modal, Table, Typography, Tag } from "antd";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";
import LoaderOverlay from 'shared/components/LoaderOverlay';

// Composant StatusInvoiceTag pour afficher le statut
const StatusInvoiceTag = ({ statusInvoice }: { statusInvoice: string }) => {
    return (
        <Tag color={statusInvoice === 'enabled' ? 'green' : 'red'}>
            {statusInvoice === 'enabled' ? 'Payé' : 'Non Payé'}
        </Tag>
    );
};

const GET_DATA = gql`
    query getData($id: Int!) {
        commandInvoice(id: $id) {
            id
            nameInvoice
            status
            periode
            meals {
                id
                price
                menu {
                    id
                    mealDefinition {
                        id
                        name
                    }
                }
                supplements {
                    id
                    price
                    dish {
                        id
                        name
                    }
                }
            }
        }
    }
`;

interface Meal {
    id: number;
    price: number;
    menu?: {
        id: number;
        mealDefinition?: {
            id: number;
            name: string;
        };
    };
    supplements?: {
        id: number;
        price: number;
        dish: {
            id: number;
            name: string;
        };
    }[];
}

interface CommandInvoice {
    id: number;
    nameInvoice: string;
    status: number;
    periode: string;
    meals: Meal[];
}

interface QueryData {
    commandInvoice: CommandInvoice;
}

interface InvoiceShowModalProps {
    invoiceId: number;
    visible: boolean;
    onClose: () => void;
}

export default function InvoiceShowModal({
                                             invoiceId,
                                             visible,
                                             onClose,
                                         }: InvoiceShowModalProps) {
    const { data, loading } = useQuery<QueryData>(GET_DATA, {
        variables: { id: invoiceId },
        fetchPolicy: "cache-and-network",
    });

    const invoice = data?.commandInvoice;
    const meals = invoice?.meals || [];

    const formatPeriodToMonth = (period: string): string => {
        const months = [
            "Janvier", "Février", "Mars", "Avril", "Mai", "Juin",
            "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"
        ];

        const [month, year] = period.split("-");
        const monthIndex = parseInt(month, 10) - 1;

        if (monthIndex >= 0 && monthIndex < months.length) {
            return `${months[monthIndex]} ${year}`;
        }

        return "Période invalide";
    };

// Exemple d'utilisation
    const formattedPeriod = formatPeriodToMonth(invoice?.periode || "");

    // Calcul des statistiques
    const mealStatistics = meals.reduce((acc, meal) => {
        const mealDefinitionId = meal.menu?.mealDefinition?.id;
        const mealDefinitionName = meal.menu?.mealDefinition?.name || "Unknown";
        const mealPrice = meal.price || 0;

        if (mealDefinitionId) {
            if (!acc[mealDefinitionId]) {
                acc[mealDefinitionId] = {
                    name: mealDefinitionName,
                    count: 0,
                    totalAmount: 0,
                    taxAmount: 0,
                    price: mealPrice, // Prix unitaire
                };
            }
            acc[mealDefinitionId].count += 1;
            acc[mealDefinitionId].totalAmount += mealPrice;
            acc[mealDefinitionId].taxAmount += mealPrice * 0.055; // TVA 5.5%
        }

        return acc;
    }, {});

    const mealStatisticsArray = Object.entries(mealStatistics).map(([id, stats]) => ({
        id,
        ...stats,
    }));

    const totalCount = mealStatisticsArray.reduce((sum, stat) => sum + stat.count, 0);
    const totalTaxAmount = mealStatisticsArray.reduce((sum, stat) => sum + stat.taxAmount, 0);
    const totalAmount = mealStatisticsArray.reduce((sum, stat) => sum + stat.totalAmount, 0);

    const supplementStatistics = meals.reduce((acc, meal) => {
        meal.supplements?.forEach((supplement) => {
            const dishId = supplement.dish?.id;
            const dishName = supplement.dish?.name || "Unknown";
            const supplementPrice = supplement.price || 0;

            if (dishId) {
                if (!acc[dishId]) {
                    acc[dishId] = {
                        name: dishName,
                        count: 0,
                        totalAmount: 0,
                        taxAmount: 0,
                        price: supplementPrice, // Prix unitaire
                    };
                }
                acc[dishId].count += 1;
                acc[dishId].totalAmount += supplementPrice;
                acc[dishId].taxAmount += supplementPrice * 0.055; // TVA 5.5%
            }
        });

        return acc;
    }, {});

    const supplementStatisticsArray = Object.entries(supplementStatistics).map(([id, stats]) => ({
        id,
        ...stats,
    }));

    const totalSupplementCount = supplementStatisticsArray.reduce((sum, stat) => sum + stat.count, 0);
    const totalSupplementTaxAmount = supplementStatisticsArray.reduce((sum, stat) => sum + stat.taxAmount, 0);
    const totalSupplementAmount = supplementStatisticsArray.reduce((sum, stat) => sum + stat.totalAmount, 0);


    const columns = [
        {
            title: <strong>Menu</strong>,
            dataIndex: "name",
            key: "name",
            align: "center",
        },
        {
            title: <strong>Prix</strong>,
            dataIndex: "price",
            key: "price",
            align: "center",
            render: (value, record) => record.id === "total" ? value : value.toFixed(2), // Formatage des décimales sauf pour "Total"
        },
        {
            title: <strong>Quantité</strong>,
            dataIndex: "count",
            key: "count",
            align: "center",
            render: (value, record) => record.id === "total" ? <strong>{value}</strong> : value, // Gras pour la ligne "Total"
        },
        {
            title: <strong>Montant TVA</strong>,
            dataIndex: "taxAmount",
            key: "taxAmount",
            align: "center",
            render: (value, record) => record.id === "total" ? <strong>{value}</strong> : value.toFixed(2), // Formatage des décimales sauf pour "Total"
        },
        {
            title: <strong>Montant TTC</strong>,
            dataIndex: "totalAmount",
            key: "totalAmount",
            align: "center",
            render: (value, record) => record.id === "total" ? <strong>{value}</strong> : value.toFixed(2), // Formatage des décimales sauf pour "Total"
        },
    ];

    // Ajouter la ligne des totaux
    const dataWithTotal = [
        ...mealStatisticsArray,
        {
            id:"total",
            name:<strong>Total</strong>,
            price:"-", // Aucun prix pour les totaux
            count:<strong>{totalCount}</strong>,
            taxAmount:<strong>{totalTaxAmount.toFixed(2)}</strong>,
            totalAmount:<strong>{totalAmount.toFixed(2)}</strong>,
            },
    ];

    const supplementColumns = [
        {
            title: <strong>Nom du Plat</strong>,
            dataIndex: "name",
            key: "name",
            align: "center",
            render: (value, record) => record.id === "total" ? <strong>{value}</strong> : value, // Gras pour la ligne "Total"
        },
        {
            title: <strong>Prix</strong>,
            dataIndex: "price",
            key: "price",
            align: "center",
            render: (value, record) => record.id === "total" ? value : value.toFixed(2), // Formatage des décimales sauf pour "Total"
        },
        {
            title: <strong>Quantité</strong>,
            dataIndex: "count",
            key: "count",
            align: "center",
            render: (value, record) => record.id === "total" ? <strong>{value}</strong> : value, // Gras pour la ligne "Total"
        },
        {
            title: <strong>Montant TVA</strong>,
            dataIndex: "taxAmount",
            key: "taxAmount",
            align: "center",
            render: (value, record) => record.id === "total" ? <strong>{value}</strong> : value.toFixed(2), // Formatage des décimales sauf pour "Total"
        },
        {
            title: <strong>Montant TTC</strong>,
            dataIndex: "totalAmount",
            key: "totalAmount",
            align: "center",
            render: (value, record) => record.id === "total" ? <strong>{value}</strong> : value.toFixed(2), // Formatage des décimales sauf pour "Total"
        },
    ];


    const supplementDataWithTotal = [
        ...supplementStatisticsArray,
        {
            id: "total",
            name: <strong>Total</strong>, // En gras
            price: "-", // Aucun prix unitaire pour les totaux
            count: <strong>{totalSupplementCount}</strong>, // Quantité totale
            taxAmount: <strong>{totalSupplementTaxAmount.toFixed(2)}</strong>, // TVA totale
            totalAmount: <strong>{totalSupplementAmount.toFixed(2)}</strong>, // Montant TTC total
        },
    ];



    return (
        <Modal
            visible={visible}
            onCancel={onClose}
            footer={null}
            width={800}
            title={
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <div style={{ flex: 1, display: "flex", justifyContent: "flex-start" }}>
                        <StatusInvoiceTag statusInvoice={invoice?.status === 1 ? 'enabled' : 'disabled'} />
                    </div>
                    <div style={{ flex: 1, textAlign: "center" }}>
                        <span>Facturation <strong>{formattedPeriod}</strong></span>
                    </div>
                    <div style={{ flex: 1 }} />
                </div>
            }
        >
            {loading && <LoaderOverlay loading />}
            <Typography.Title level={4} style={{ textAlign: "center", marginTop: 16 }}>
                Menus
            </Typography.Title>
            <Table
                dataSource={dataWithTotal}
                columns={columns}
                rowKey="id"
                pagination={false}
                bordered
                style={{ marginBottom: 24 }}
            />
            <Typography.Title level={4} style={{ textAlign: "center", marginTop: 16 }}>
                Suppléments
            </Typography.Title>
            <Table
                dataSource={supplementDataWithTotal}
                columns={supplementColumns}
                rowKey="id"
                pagination={false}
                bordered
            />
        </Modal>

    );
}
