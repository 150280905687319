import React, { useState } from 'react';
import { Form, Button, Row, Col, InputNumber, Card } from 'antd';
import InputNumberService from 'shared/services/InputNumberService';

interface CommandinvoiceFormProps {
    fields: any[];
    onChange: (fields: any[]) => void;
    onSubmit: (values: any) => void;
    loading: boolean;
    refetch: () => void;
}

export default function CommandinvoiceForm({
                                               fields,
                                               onChange,
                                               onSubmit,
                                               loading,
                                               refetch,
                                           }: CommandinvoiceFormProps) {
    const [, forceUpdate] = useState();
    const [form] = Form.useForm();

    return (
        <Card bordered={false} style={{ maxWidth: 600, margin: '0 auto', padding: '20px' }}>
            <Form
                layout="vertical"
                form={form}
                fields={fields}
                onFieldsChange={(_, fields: any) => {
                    onChange(fields);
                }}
                onFinish={onSubmit}
                hideRequiredMark
            >
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <Form.Item
                            name="apaPrice"
                            label="Prix de l'APA TTC"
                            rules={[
                                {
                                    type: 'number',
                                    message: 'Le prix doit être une valeur numérique',
                                    validator: InputNumberService.inputNumberValidator,
                                },
                                { required: true, message: 'Ce champ est requis' },
                            ]}
                        >
                            <InputNumber style={{ width: '100%' }} placeholder="Saisissez le prix de l'APA TTC" />
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <Form.Item
                            name="priceTotalPaid"
                            label="Montant total TTC déjà payé"
                            rules={[
                                {
                                    type: 'number',
                                    message: 'Le prix doit être une valeur numérique',
                                    validator: InputNumberService.inputNumberValidator,
                                },
                                { required: true, message: 'Ce champ est requis' },
                            ]}
                        >
                            <InputNumber style={{ width: '100%' }} placeholder="Saisissez le montant payé" />
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <Form.Item
                            name="priceTotalDue"
                            label="Montant total TTC restant"
                        >
                            <InputNumber style={{ width: '100%' }} placeholder="Calcul automatique" readOnly />
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <Form.Item
                            name="priceTotal"
                            label="Montant total TTC"
                            rules={[{ required: true, message: 'Ce champ est requis' }]}
                        >
                            <InputNumber style={{ width: '100%' }} placeholder="Calcul automatique" readOnly />
                        </Form.Item>
                    </Col>
                </Row>

                <Row justify="center" style={{ marginTop: 20 }}>
                    <Button
                        type="primary"
                        htmlType="submit"
                        loading={loading}
                        shape="round"
                        style={{ padding: '0 40px', height: '40px' }}
                    >
                        Enregistrer
                    </Button>
                </Row>
            </Form>
        </Card>
    );
}